import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */
import SocialMedia from "../socialMedia/SocialMedia";
export default function Footer(props) {
  const theme = {
    body: "#011627",
    text: "#ffffff",
    highlight: "#2ec4b6",
    dark: "#000000",
    secondaryText: "#63aeff",
    imageHighlight: "#0E6BA8",
    compImgHighlight: "#E6E6E6",
    jacketColor: "#0A2472",
    headerColor: "#0E6BA877",
  };
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: theme.secondaryText }}>
        <SocialMedia theme={theme} />
          {/* Made with <span role="img">❤️</span> by {greeting.title} */}
          Copyright @ 2020 {greeting.title}. All rights reserved.
        </p>
        {/* <ToggleSwitch theme={props.theme} onToggle={props.onToggle}/> */}
      </Fade>
    </div>
  );
}
