/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
	isSplash: false, // Change this to false if you don't want Splash screen.
};

//Home Page
const greeting = {
	hello: "Hello, hola, नमस्ते ! 🙏",
	title: "Parth Maniar",
	logo_name: "Parth Maniar",
	nickname: "theteacoder",
	subTitle:
		"A self-motivated and passionate individual who loves to code 👨🏻‍💻, build and collaborate 🤝 for creating impactful software products.",
	resumeLink:
		"https://theteacoder.tech/ParthManiar-Resume.pdf",
	portfolio_repository: "https://github.com/officialpm",
};

const socialMediaLinks = [

	{
		name: "Github",
		link: "https://github.com/officialpm",
		fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
		backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
	},
	{
		name: "LinkedIn",
		link: "https://www.linkedin.com/in/parthdmaniar/",
		fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
		backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
	},
	{
		name: "YouTube",
		link: "https://www.youtube.com/channel/UCg1pRBEOMAGBZZg4AKQVOdg",
		fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
		backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
	},
	{
		name: "Gmail",
		link: "mailto:parth@buffalo.edu",
		fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
		backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
	},
	{
		name: "Twitter",
		link: "https://twitter.com/theteacoder",
		fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
		backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
	},
	{
		name: "Facebook",
		link: "https://www.facebook.com/parthroxx/",
		fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
		backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
	},
	{
		name: "Instagram",
		link: "https://www.instagram.com/theteacoder/",
		fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
		backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
	},
];

const skills = {
	data: [
		{
			title: "Data Science & AI",
			fileName: "DataScienceImg",
			skills: [
				"⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
				"⚡ Experience of working with Computer Vision and NLP projects",
				"⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
			],
			softwareSkills: [
				{
					skillName: "Tensorflow",
					fontAwesomeClassname: "logos-tensorflow",
					style: {
						backgroundColor: "transparent",
					},
				},
				{
					skillName: "Keras",
					fontAwesomeClassname: "simple-icons:keras",
					style: {
						backgroundColor: "white",
						color: "#D00000",
					},
				},

				{
					skillName: "Python",
					fontAwesomeClassname: "ion-logo-python",
					style: {
						backgroundColor: "transparent",
						color: "#3776AB",
					},
				},
			],
		},
		{
			title: "Full Stack Development",
			fileName: "FullStackImg",
			skills: [
				"⚡ Building resposive website front end using React-Redux",
				"⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
				"⚡ Creating application backend in Node, Express & Flask",
			],
			softwareSkills: [
				{
					skillName: "HTML5",
					fontAwesomeClassname: "simple-icons:html5",
					style: {
						color: "#E34F26",
					},
				},
				{
					skillName: "CSS3",
					fontAwesomeClassname: "fa-css3",
					style: {
						color: "#1572B6",
					},
				},
				{
					skillName: "Sass",
					fontAwesomeClassname: "simple-icons:sass",
					style: {
						color: "#CC6699",
					},
				},
				{
					skillName: "JavaScript",
					fontAwesomeClassname: "simple-icons:javascript",
					style: {
						backgroundColor: "#000000",
						color: "#F7DF1E",
					},
				},
				{
					skillName: "ReactJS",
					fontAwesomeClassname: "simple-icons:react",
					style: {
						color: "#61DAFB",
					},
				},
				{
					skillName: "NodeJS",
					fontAwesomeClassname: "simple-icons:node-dot-js",
					style: {
						color: "#339933",
					},
				},
				{
					skillName: "NPM",
					fontAwesomeClassname: "simple-icons:npm",
					style: {
						color: "#CB3837",
					},
				},
				{
					skillName: "Yarn",
					fontAwesomeClassname: "simple-icons:yarn",
					style: {
						color: "#2C8EBB",
					},
				},
				{
					skillName: "Gatsby",
					fontAwesomeClassname: "simple-icons:gatsby",
					style: {
						color: "#663399",
					},
				},
				{
					skillName: "Flutter",
					fontAwesomeClassname: "simple-icons:flutter",
					style: {
						color: "#02569B",
					},
				},
			],
		},
		{
			title: "Cloud Infra-Architecture",
			fileName: "CloudInfraImg",
			skills: [
				"⚡ Experience working on multiple cloud platforms",
				"⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
				"⚡ Deploying deep learning models on cloud to use on mobile devices",
			],
			softwareSkills: [

				{
					skillName: "AWS",
					fontAwesomeClassname: "simple-icons:amazonaws",
					style: {
						color: "#FF9900",
					},
				},

				{
					skillName: "MongoDB",
					fontAwesomeClassname: "simple-icons:mongodb",
					style: {
						color: "#47A248",
					},
				},
				{
					skillName: "Docker",
					fontAwesomeClassname: "simple-icons:docker",
					style: {
						color: "#1488C6",
					},
				},

			],
		}
	],
};

// Education Page
const competitiveSites = {
	competitiveSites: [
		{
			siteName: "HackerRank",
			iconifyClassname: "simple-icons:hackerrank",
			style: {
				color: "#2EC866",
			},
			profileLink: "https://github.com/officialpm",
		},
		{
			siteName: "Kaggle",
			iconifyClassname: "simple-icons:kaggle",
			style: {
				color: "#20BEFF",
			},
			profileLink: "https://www.kaggle.com/officialpm",
		},
	],
};

const degrees = {
	degrees: [
		{
			title: "University at Buffalo",
			subtitle: "Masters in Data Science",
			logo_path: "UB_WHT_RGB.png",
			alt_name: "UBuffalo",
			duration: "2021 - 2022 (Expected)",
			descriptions: [
	
			],
			website_link: "https://buffalo.edu/",
		},
		{
			title: "K. J. Somaiya Institute of Engineering and Information Technology",
			subtitle: "BE in Information Technology",
			logo_path: "somaiyalogo.png",
			alt_name: "KJSIEIT",
			duration: "2017 - Present",
			descriptions: [
				"⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
				"⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
			],
			website_link: "https://kjsieit.somaiya.edu",
		},
	],
};

const certifications = {
	certifications: [
		{
			title: "Core Programming",
			subtitle: "- NIIT Limited",
			logo_path: "NIIT.png",
			certificate_link:
				"https://drive.google.com/open?id=1r1683IsS2uj-VjKVFYxWltoskYD0r3l-",
			alt_name: "NIIT",
			color_code: "#ffffff",
		},
		{
			title: "Natural Language Processing in TensorFlow",
			subtitle: "- deeplearning.ai",
			logo_path: "deeplearning_ai_logo.png",
			certificate_link:
				"https://www.coursera.org/account/accomplishments/records/JB3K6DY7J72P",
			alt_name: "deeplearning.ai",
			color_code: "#ffffff",
		},
		{
			title: "AI From the Data Center to the Edge – An Optimized Path Using Intel® Architecture",
			subtitle: "- Intel Corporation",
			logo_path: "intel.jpeg",
			certificate_link:
				"https://www.credential.net/aee54f72-dcdc-41be-94f1-17caaf35faa8",
			alt_name: "Intel AI",
			color_code: "#ffffff",
		}, {
			title: "Introduction to Big Data",
			subtitle: "- University of California San Diego",
			logo_path: "UCSanDiego.jpeg",
			certificate_link:
				"https://www.coursera.org/account/accomplishments/records/XG56KSUGLKJJ",
			alt_name: "Big Data",
			color_code: "#ffffff",
		},

	],
};

// Experience Page
const experience = {
	title: "Experience",
	subtitle: "Work and Internship",
	description:
		"Worked with both Product and Service based companies developing web and mobile applications. Active colloborator in tech community forums",
	header_image_path: "experience.svg",
	sections: [

		{
			title: "Internships - 10",
			experiences: [
				{
					title: "R&D Intern",
					company: "TATA POWER, MUMBAI",
					company_url: "https://www.linkedin.com/company/tata-power/",
					logo_path: "tatapowerlogo.png",
					duration: "2 Dec 2019 - Ongoing",
					location: "Mumbai, India",
					description:
						"Developing a tool for Tata Power, Mumbai to forecast load for a particular day i.e. estimating power consumption for a specific region for a specific day. Incorporation of various features has contributed towards the development of an efficient tool that is currently being used by the organization.",
					color: "#9b1578",
					languages: [
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},
						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},
						{
							"name": "Redux",
							"iconifyClass": "logos:redux"
						},
						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},

						{
							"name": "Python",
							"iconifyClass": "logos-python"
						},
						{
							"name": "MongoDB",
							"iconifyClass": "logos:mongodb"
						},
						{
							"name": "Tensorflow",
							"iconifyClass": "logos:tensorflow"
						}
					]
				},
				{
					title: "AI Developer",
					company: "INDIA METEOROLOGICAL DEPARTMENT, MUMBAI ",
					company_url: "https://mausam.imd.gov.in/",
					logo_path: "imdlogo.png",
					duration: "2 Dec 2019 - Ongoing",
					location: "Mumbai, India",
					description:
						"Developing a system for India Meteorological Department, Mumbai for weather prediction in which efficient prognosis is done by dividing the day into 96 blocks of 15-minute time span each.",
					color: "#9b1578",
					languages: [
						{
							"name": "Tensorflow",
							"iconifyClass": "logos:tensorflow"
						},
						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},

						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "Python",
							"iconifyClass": "logos-python"
						},
						{
							"name": "MongoDB",
							"iconifyClass": "logos:mongodb"
						},
						{
							"name": "Keras",
							"iconifyClass": "logos:keras"
						}
					]
				}, {
					title: "Software Consultant",
					company: "V R TAX CONSULTANTS",
					company_url: "http://www.vrtaxconsultants.com/",
					logo_path: "vrlogo.png",
					duration: "1 Jul 2020 - 25 Jul 2020",
					location: "Mumbai, India",
					description:
						["Developed RPA Tool for Bulk generating of XML files along with draft copy in PDF required for generating certificates in line with the requirements of utility as provided by the Ministry of Finance (Income Tax Act). Developed a tool for bulk downloading of PDF files from the income tax website. Developed a tool for updating UDIN details on the income tax website and also printing the same in PDF files. Developed an automated tool for verifying PAN details from the income tax website.",],
					color: "#9b1578",
					languages: [

						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},

						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},

					]
				}, {
					title: "Virtual Internship",
					company: "JPMORGAN CHASE & CO.",
					company_url: "https://www.linkedin.com/company/jpmorganchase/",
					logo_path: "jplogo.jpg",
					duration: "1st Jun 2020 - 5th Jun 2020",
					location: "Mumbai, India",
					description:
						["Establishing Data Feed (Python Testing, Git and Spinning up a local server along with Socket Connections). "
							, "Bit of Front-End Web Development using React and TypeScript (Plotting Graphs). "
							, "Data visualization (Making them more interactive with Perspective Tool). "
						],
					color: "#9b1578",
					languages: [

						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},

						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},

					]
				}, {
					title: "Project Engineering Intern",
					company: "ENRICH TECHNOLOGY",
					company_url: "https://www.linkedin.com/company/enrich-technology/",
					logo_path: "et-logo.png",
					duration: "1st Oct 2019 - 6th Jan 2020",
					location: "Bengaluru, India",
					description:
						"Worked on the project titled ‘QuaranTime App’ using Expo and NodeJS. This was developed amidst this global pandemic and aims to help the people globally. The application is an One - Stop solution for managing the entire corona cycle of a suspected citizen from monitoring the Quarantine till the recovery with Medical Assistance and it uses face recognition and geo fencing.",
					color: "#9b1578",
					languages: [

						{
							"name": "Expo",
							"iconifyClass": "logos:expo"
						},
						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "MongoDB",
							"iconifyClass": "logos:mongodb"
						},
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},
						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						}
					]
				},
				{
					title: "Blockchain Intern",
					company: "ENRICH TECHNOLOGY",
					company_url: "https://www.linkedin.com/company/enrich-technology/",
					logo_path: "et-logo.png",
					duration: "1st Oct 2019 - 6th Jan 2020",
					location: "Bengaluru, India",
					description:
						"Worked on the project titled ‘BlockChain Asset Management’ using BigChainDB, Angular and Nodejs. It is a web based application to maintain and track assets throughout its lifecycle.",
					color: "#9b1578",
					languages: [
						{
							"name": "JavaScript",
							"iconifyClass": "logos-angular"
						},

						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},

					]
				},
				{
					title: "Fullstack Intern",
					company: "ENRICH TECHNOLOGY",
					company_url: "https://www.linkedin.com/company/enrich-technology/",
					logo_path: "et-logo.png",
					duration: "1st Oct 2019 - 6th Jan 2020",
					location: "Bengaluru, India",
					description:
						"Worked on the project titled ‘Complaint Management System’ using Angular, React Native and MongoDB.",
					color: "#9b1578",
					languages: [
						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "MongoDB",
							"iconifyClass": "logos:mongodb"
						},
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},
						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},
					]
				},
				{
					title: "Project Engineering Intern",
					company: "NXTGEN INFINITE DATACENTER",
					company_url: "https://www.linkedin.com/company/nxtgen-datacenter-&-cloud-services-limited/",
					logo_path: "nxtgenlogo.png",
					duration: "Aug 19 - Oct 2019",
					location: "Mumbai, India",
					description:
						"Built a product called “Multiverse” which is a Unique ID Generation tool with Facial Authentication and Liveness Detection System created using face-api, EAR & Keycloak.",
					color: "#9b1578",
					languages: [

						{
							"name": "Tensorflow",
							"iconifyClass": "logos:tensorflow"
						},
						{
							"name": "NodeJs",
							"iconifyClass": "logos:nodejs"
						},
						{
							"name": "MongoDB",
							"iconifyClass": "logos:mongodb"
						},
						{
							"name": "Python",
							"iconifyClass": "logos-python"
						},
						{
							"name": "JavaScript",
							"iconifyClass": "logos-javascript"
						},
						{
							"name": "ReactJS(Web & Mobile)",
							"iconifyClass": "simple-icons:react",
							"style": {
								"color": "#61DAFB",
								"backgroundColor": "#2c2e39",
								"borderRadius": "50px",
								"padding": "5px"
							}
						},
					]
				},
				{
					title: "Summer Intern",
					company: "NXTGEN INFINITE DATACENTER",
					company_url: "https://www.linkedin.com/company/nxtgen-datacenter-&-cloud-services-limited/",
					logo_path: "nxtgenlogo.png",
					duration: "3rd Jun 2019 - 3rd Jul 2019",
					location: "Mumbai, India",
					description:
						"Worked on projects involving development of specific tools namely Automated Service Provisioning Manager and Migration on Multi-node Openstack with operations including VM migration, auto-scaling and alert mechanism.",
					color: "#9b1578",
					languages: [

						{
							"name": "Ansible",
							"iconifyClass": "logos:ansible"
						},
						{
							"name": "OpenStack",
							"iconifyClass": "logos:openstack"
						},
					]
				},
				{
					title: "Intern",
					company: "THE INSTITUTION OF ENGINEERING AND TECHNOLOGY ",
					company_url: "https://www.linkedin.com/company/myanatomy/",
					logo_path: "ietlogo.png",
					duration: "2nd Mar 2019 - 9th Mar 2019",
					location: "Mumbai, India",
					description:
						"Worked on projects based on PHP, Android Development & IoT.",
					color: "#9b1578",
					languages: [
						{
							"name": "Android",
							"iconifyClass": "logos-android"
						},
						{
							"name": "PHP",
							"iconifyClass": "logos-php"
						},
						{
							"name": "Arduino",
							"iconifyClass": "logos:arduino"
						},

					]
				}
			],
		},
		// {
		// 	title: "Volunteerships",
		// 	experiences: [
		// 		{
		// 			title: "Google Explore ML Facilitator",
		// 			company: "Google",
		// 			company_url: "https://about.google/",
		// 			logo_path: "google_logo.png",
		// 			duration: "June 2019 - April 2020",
		// 			location: "Hyderabad, Telangana",
		// 			description:
		// 				"Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
		// 			color: "#4285F4",
		// 		},
		// 		{
		// 			title: "Microsoft Student Partner",
		// 			company: "Microsoft",
		// 			company_url: "https://www.microsoft.com/",
		// 			logo_path: "microsoft_logo.png",
		// 			duration: "Aug 2019 - May 2020",
		// 			location: "Hyderabad, Telangana",
		// 			description:
		// 				"Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
		// 			color: "#D83B01",
		// 		},
		// 		{
		// 			title: "Mozilla Campus Captain",
		// 			company: "Mozilla",
		// 			company_url: "https://www.mozilla.org/",
		// 			logo_path: "mozilla_logo.png",
		// 			duration: "Oct 2019 - May 2020",
		// 			location: "Kurnool, Andhra Pradesh",
		// 			description:
		// 				"My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
		// 			color: "#000000",
		// 		},
		// 		{
		// 			title: "Developer Students Club Member",
		// 			company: "DSC IIITDM Kurnool",
		// 			company_url:
		// 				"https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
		// 			logo_path: "dsc_logo.png",
		// 			duration: "Jan 2018 - May 2020",
		// 			location: "Kurnool, Andhra Pradesh",
		// 			description:
		// 				"We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
		// 			color: "#0C9D58",
		// 		},
		// 		{
		// 			title: "Developer Program Member",
		// 			company: "Github",
		// 			company_url: "https://github.com/",
		// 			logo_path: "github_logo.png",
		// 			duration: "July 2019 - PRESENT",
		// 			location: "Work From Home",
		// 			description:
		// 				"I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating peoper documentation for project.",
		// 			color: "#181717",
		// 		},
		// 	],
		// },
	],
};

// Projects Page
const projectsHeader = {
	title: "Projects",
	description:
		"Things I build when I am learning something new or just for fun.",
	avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
	contactSection: {
		title: "Contact Me",
		profile_image_path: "ParthManiar.png",
		description:
			"I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
	},
	blogSection: {
		title: "Blogs",
		subtitle:
			"For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
		link: "https://ashutoshhathidara.wordpress.com",
		avatar_image_path: "blogs_image.svg",
	},
	addressSection: {
		title: "Address",
		subtitle:
			"Buffalo, United States",
		avatar_image_path: "address_image.svg",
		location_map_link: "https://goo.gl/maps/4kdCsWqyoGwuxAtL",
	},
	phoneSection: {
		title: "Phone Number",
		subtitle: "+1 7165797667",
	},
	emailSection: {
		title: "Email",
		subtitle: "parthmaniar@gmail.com",
	},
};

export {
	settings,
	greeting,
	socialMediaLinks,
	skills,
	competitiveSites,
	degrees,
	certifications,
	experience,
	projectsHeader,
	contactPageData,
};
